'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.assets.factory:AssetsOverviewManager

 # @description

###
angular
  .module 'mundoAdmin.assets'
  .factory 'AssetsOverviewManager', [
    'MundoDefaultManager'
    'AssetDataManager'
    '_'
    (
      MundoDefaultManager
      AssetDataManager
      _
    ) ->
      AssetsOverviewManagerBase = new MundoDefaultManager()
      AssetsOverviewManagerBase.setUrl('assets')
      AssetsOverviewManagerBase.setSearchFields(['label', 'assetType.label'])
      AssetsOverviewManagerBase.setActions(['list', 'search', 'print'])

      AssetsOverviewManagerBase.getList = (query = @defaultQuery) ->
        params = {}

        params.offset = query.limit * query.page - query.limit
        params.limit = query.limit

        if query.sort.substr(0,1) == "-"
          params.sort = query.sort.substr(1) + ',DESC'
        else
          params.sort = query.sort.substr(0) + ',ASC'

        if query.search.active
          searchString = ''
          searchFields =  @getSearchFields()
          _.each searchFields, (field) ->
            searchString = "#{searchString},#{field},like #{query.search.query}"

          params['filter[]'] = "OR#{searchString}"

        @transport.all(@getUrl()).getList(params).then (assets)->
          _.each assets, (asset, index) ->
            assets[index] = angular.extend(assets[index],
              _.groupBy (_.filter asset.assetData ,(item) -> !item.deletedAt?) , "type")
          assets

      AssetsOverviewManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'VehicleIdentificationNumber.value'
          title: 'datatable.label.vehicleIdentificationNumber'
          multiple: 'VehicleIdentificationNumber'
        ,
          key: 'LicensePlateNumber.value'
          title: 'datatable.label.licensePlateNumber'
          multiple:'LicensePlateNumber'
        ,
          key: 'activation date'
          title: 'datatable.label.activationDate'
          type: 'dateTime'
        ,
          key: 'PoliceVehicle.roofNumber'
          title: 'datatable.label.roofNumber'
          multiple:'PoliceVehicle'
        ,
          key: 'PoliceVehicle.isUnmarked'
          title: 'datatable.label.indication'
          multiple:'PoliceVehicle'
          type: 'boolean'
        ,
          key: 'comments'
          title: 'datatable.label.comments'
        ]
      AssetsOverviewManagerBase
  ]
